import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Menubar from "./Components/Headers/Menubar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Service from "./Pages/Service";
import ServiceDetails from "./Pages/ServiceDetails";
import Header from "./Components/Headers/Header";
import Footer from "./Components/Footers/Footer";
import TermsCondition from "./Pages/TermsConditions/TermsCondition";
import GalleryPage from "./Pages/ImageGallery/GalleryPage";
import ScrollToTop from "./Components/ScrollToTop";
import OurVision from "./Pages/OurVision/OurVision";

function App() {
  return (
    <div className="App">
      <div className="fixed-top">
        {/* <Header /> */}
        <Menubar />
      </div>
      <div className="page-body container">
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/product" element={<Service />} />
          <Route exact path="/our-vision" element={<OurVision />} />
          <Route
            exact
            path="/product/category/:id"
            element={<ServiceDetails />}
          />
          <Route exact path="/terms-conditions" element={<TermsCondition />} />
          <Route exact path="/gallery" element={<GalleryPage />} />
        </Routes>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
