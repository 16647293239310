import React from "react";
import "../homepageStyle.css";
import Heater_insulation_cover_5 from "../../assets/HeaterInsulationCover/Heater_insulation_cover_5.jpg";
import DG_Exhaust_Silencer_Pipe_1 from "../../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_1.jpg";
import Expansion_bellow_Fabrics_6 from "../../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_6.jpg";
import Insulation_pipe_line_3 from "../../assets/Insulation_pipe_line/Insulation_pipe_line_3.jpg";
import Turbine_Insulation_Pad_2 from "../../assets/TurbineInsulationPad/Turbine_Insulation_Pad_2.jpg";
import Valve_Insulation_Jacket_2 from "../../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_2.jpg";

export default function HomePage() {
  return (
    <>
      <div className="home-carousel">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Heater_insulation_cover_5}
                className="d-block w-100"
                alt="Removable Insulation Covers"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Heater Insulation Cover</h5>
                <h6>Maximize Efficiency and Comfort!</h6>
                <p>
                  Our Heater Insulation Cover is designed to enhance the
                  efficiency of your heating system while reducing energy costs.
                  Made from high-quality, thermal-resistant materials, this
                  cover effectively traps heat, preventing it from escaping and
                  ensuring that your space stays warm and cozy
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={DG_Exhaust_Silencer_Pipe_1}
                className="d-block w-100"
                alt="DG Exhaust Silencer Pipe"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>DG Exhaust Silencer Pipe</h5>
                <h6>Optimize Performance and Minimize Noise!</h6>
                <p>
                  The DG Exhaust Silencer Pipe is engineered for superior
                  performance and sound management in your vehicle. Designed
                  specifically for [insert vehicle type or model], this silencer
                  pipe enhances exhaust flow while significantly reducing noise
                  levels, allowing you to enjoy a smoother and quieter ride.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Expansion_bellow_Fabrics_6}
                className="d-block w-100"
                alt="Expansion Bellow Fabrics"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Expansion Bellow Fabrics</h5>
                <h6>Durable and Flexible Solutions for Your Needs!</h6>
                <p>
                  Introducing Expansion Bellow Fabrics, your go-to solution for
                  effective movement and vibration control in various
                  applications. These high-performance fabrics are designed to
                  provide exceptional flexibility and durability, making them
                  ideal for a wide range of industrial and commercial uses.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Insulation_pipe_line_3}
                className="d-block w-100"
                alt="Insulation Pipe Line"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Insulation Pipe Line</h5>
                <h6>Efficient Energy Solutions for Your Systems!</h6>
                <p>
                  Introducing our Insulation Pipe Line, designed to enhance
                  thermal efficiency and reduce energy costs in a variety of
                  applications. Whether for residential, commercial, or
                  industrial use, our insulation solutions provide superior
                  protection against heat loss and condensation, ensuring
                  optimal performance of your piping systems.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Turbine_Insulation_Pad_2}
                className="d-block w-100"
                alt="Turbine Insulation Pad"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Turbine Insulation Pad</h5>
                <h6>Enhance Efficiency and Performance!</h6>
                <p>
                  Introducing the Turbine Insulation Pad, specifically designed
                  to improve the thermal management of turbines in various
                  industrial applications. Our high-performance insulation pad
                  helps maintain optimal operating temperatures, ensuring
                  efficiency and longevity of your turbine systems.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Valve_Insulation_Jacket_2}
                className="d-block w-100"
                alt="Valve Insulation Jacket"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Valve Insulation Jacket</h5>
                <h6>Optimize Performance and Save Energy!</h6>
                <p>
                  Introducing the Valve Insulation Jacket, your solution for
                  enhanced thermal efficiency in valve systems. Designed to
                  minimize heat loss and prevent condensation, our insulation
                  jacket is essential for maintaining optimal temperatures in
                  various industrial applications.
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
