import React from "react";
import "./vision.css";
import { Link } from "react-router-dom";
import Heater_insulation_cover_2 from "../../assets/HeaterInsulationCover/Heater_insulation_cover_2.jpg";

export default function OurVision() {
  return (
    <>
      <div className="our-vision-section">
        <div className="our-vision">
          <section className="hero mt-5">
            <div>
              <h1>Our Vision</h1>
              <p>Leading the way in innovative insulation technology.</p>
            </div>
          </section>

          <section className="vision-statement mt-5">
            <h2>
              Our <span>Vision</span>
            </h2>
            <p className=" text-sm-center">
              At New Tech Engineering, our vision is to be a leader in
              innovative insulation solutions that enhance energy efficiency and
              environmental sustainability. We strive to create products that
              not only meet the diverse needs of our customers but also
              contribute to a greener planet. Our commitment to excellence and
              continuous improvement drives us to push the boundaries of
              insulation technology, ensuring safe, comfortable, and efficient
              living spaces for generations to come.
            </p>
          </section>

          <section className="technology-overview mt-5">
            <div className=" row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <h2>Technology Overview.</h2>
                <h6>Advanced Insulation Solutions</h6>
                <div className="tech-details">
                  <p>
                    Our range of insulation products is designed with
                    cutting-edge technology to deliver superior thermal
                    performance, sound attenuation, and fire resistance. We
                    leverage the latest advancements in material science and
                    engineering to provide effective solutions tailored for
                    various applications, including residential, commercial, and
                    industrial settings.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="interactive-tech">
                  <img
                    src={Heater_insulation_cover_2}
                    alt="Insulation Jacket"
                    className="tech-image"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="manufacturing-process">
            <h2>Manufacturing Process.</h2>
            <div className="steps mt-3">
              <div className="step">
                <h5>
                  Step 1: <span>Material Selection</span>
                </h5>
                <p>We source the highest quality materials...</p>
              </div>
              <div className="step">
                <h5>Step 2: Cutting and Shaping</h5>
                <p>
                  Our state-of-the-art technology cuts and shapes materials
                  precisely...
                </p>
              </div>
              <div className="step">
                <h5>Step 3: Assembly</h5>
                <p>Each jacket is meticulously assembled...</p>
              </div>
              <div className="step">
                <h5>Step 4: Quality Control</h5>
                <p>
                  Our rigorous quality control ensures each jacket meets our
                  high standards...
                </p>
              </div>
            </div>
          </section>

          <section className="cta mt-5">
            <h2>Ready to Experience Our Innovation?</h2>
            <Link to="/contact" className="btn-btn-primary mt-3">
              Contact Us
            </Link>
          </section>
        </div>
      </div>
    </>
  );
}
