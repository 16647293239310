import React from "react";
import "./Footers.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/new-logo.png";
import { contact, metaDetails } from "../../Common/Data";
import ISO_logo from "../../assets/ISO_logo.png";

export default function Footer() {
  const footerNavs = [
    {
      label: "Quick link",
      items: [
        {
          href: "/product",
          name: "Products",
        },
        {
          href: "/about",
          name: "About US",
        },
        {
          href: "/contact",
          name: "Contact",
        },
      ],
    },
    {
      label: "Contact",
      items: [
        {
          href: "#",
          name: "Email",
        },
        {
          href: "#",
          name: "Mobile",
        },
        {
          href: `mailto:${contact?.email}?subject=NTI Product Inquiry`,
          name: "To more info",
        },
      ],
    },
    {
      label: "Branch Office",
      items: [
        {
          href: "#",
          name: "80, Surendra Manik, Barkheda Pathani Bhopal (M.P.) 462022",
        },
        {
          href: "#",
          name: "socialMediaLink",
        },
      ],
    },
  ];
  return (
    <>
      <footer>
        <svg
          className="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div className="container row mt-5">
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <h1>New Tech Engineering</h1>
            <p>
              New Tech Engineering (Insulation) specializes in the field of R&D
              production & has became the leader in the field of application for
              industrial energy saving heating technology in india & abroad.
            </p>
            <Link to="/about">Read more..</Link>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 ps-5">
            <h2>About Us</h2>
            <ul>
              <li>
                <Link to="/about">About our company</Link>
              </li>
              <li>
                <Link to="/product">Our Products</Link>
              </li>
              <li>
                <Link to="/gallery">Our Gallery</Link>
              </li>
              <li>
                <Link to="/our-vision">Our Manufacturing Process</Link>
              </li>
              <li>
                <Link to="terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <h2>Contact Us</h2>
            <ul>
              <li>
                24, 143-A, Sector H, Govindpura,
                <br /> Industrial Area, Bhopal, Madhya Pradesh 462023, India.
              </li>
              <li>Phone: {contact?.mobile}</li>
              <li>Email: {contact?.email}</li>
              <li>GSTIN: {metaDetails?.GSTIN}</li>
              {/* <li>PAN NO: {metaDetails?.PAN}</li> */}
              <li>{`[Certified: ${metaDetails?.ISO}]`}</li>
            </ul>
            {/* <div className="ISO_section">
              <img src={ISO_logo} className="iso_image" />
            </div> */}
            <div className="contact-icons">
              <div className="contact-icon">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/i/flow/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/accounts/login/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="copyright-text">
              <p>
                Copyright © 2022{" -"}
                {new Date().getFullYear()}{" "}
                <a href="http://newtechinsulation.in/">newtechinsulation</a>.
                All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
