import React, { useState } from "react";
import "./Products/product.css";
import { Link } from "react-router-dom";
import { service } from "../Common/Data.js";

const Service = () => {
 

  return (
    <>
       <div className="our-product">
        <Link to="/product" className="">
          <div className="head-text">
            Insulation
            <span> Products</span>
          </div>
        </Link>
        <div className="product-card mt-3 row">
          {service &&
            service?.map((item, i) => {
              return (
                <div className="col-sm-12 col-md-2 col-lg-4 col-xl-4" key={i}>
                  <div className="card mb-3" style={{ width: "100%" }}>
                    <img
                      src={item.img1}
                      className="card-img-top"
                      alt={item.name}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.name}</h5>
                      <p className="card-text">
                        {item.description.length > 100
                          ? `${item.description.substring(0, 100)}...`
                          : item.description}
                      </p>{" "}
                      <div className="text-center">
                        <Link
                          to={`/product/category/${item.id}`}
                          className="btn-btn-secondary"
                        >
                          View More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Service;
