import React from "react";
import { metaDetails, title } from "../Common/Data";

const About = () => {
  return (
    <section className="about">
      <div className="about-area">
        <div className="main-title">
          <h2>
            About <span>{title}</span>
            <span className="bg-text">About {title}</span>
          </h2>
        </div>
        <div className="row about-container">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <h4>{title}</h4>
            <p>
              New Tech Engineering (Insulation) specializes in R&D production
              and has become a leader in industrial energy-saving heating
              technology both in India and abroad.
              <br />
              <br />
              We provide high-quality, reliable engineered thermal insulation
              solutions applicable across various sectors.
            </p>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7">
            <div className="row stats-container">
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-card">
                  <p className="large-text">{metaDetails.yearOfExp}</p>
                  <p className="small-text">
                    Experience providing insulation services
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-card">
                  <p className="large-text">{metaDetails.annualTurnOver}</p>
                  <p className="small-text">Annual turnover</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-card">
                  <p className="large-text">{metaDetails.noOfEmployee}</p>
                  <p className="small-text">Employees</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-stats">
          <h5 className="stat-title">We are committed to offering</h5>
          <ul className="commitment-list">
            <li>High-quality products</li>
            <li>Cost-effective pricing</li>
            <li>On-time delivery</li>
          </ul>
        </div>

        <div className="about-stats">
          <h5 className="stat-title">Track Record</h5>
          <ul className="commitment-list">
            <li>{metaDetails.yearOfExp} of experience in insulation</li>
            <li>Annual turnover of {metaDetails.annualTurnOver}</li>
            <li>{metaDetails.noOfEmployee} employees</li>
          </ul>
        </div>

        <div className="about-stats">
          <h5 className="stat-title">Design & Development</h5>
          <ul className="commitment-list">
            <li>Working with safety, health, and hygiene standards</li>
            <li>Using CATIA Software and AutoCAD for 2D & 3D drawings</li>
          </ul>
        </div>
        <div className="tech-about-stats">
          <header>
            <h1>Advanced Insulation Solutions</h1>
            <p>Cutting-edge technology for superior performance.</p>
          </header>

          <section className="product-section">
            <h2>Technology Overview</h2>
            <h5>Advanced Insulation Solutions</h5>
            <p>
              Our range of insulation products is designed with cutting-edge
              technology to deliver superior thermal performance, sound
              attenuation, and fire resistance. We leverage the latest
              advancements in material science and engineering to provide
              effective solutions tailored for various applications, including
              residential, commercial, and industrial settings.
            </p>
            <div className="product" id="thermal-insulation">
              <h5>Thermal Insulation</h5>
              <ul>
                <li>
                  <strong>Fiberglass Insulation:</strong> Lightweight and
                  non-combustible, offering excellent thermal resistance.
                </li>
                <li>
                  <strong>Foam Board Insulation:</strong> Rigid panels that
                  provide high insulating value and moisture resistance.
                </li>
              </ul>
            </div>

            <div className="product" id="sound-insulation">
              <h5>Sound Insulation</h5>
              <p>Effective solutions to minimize sound transmission for:</p>
              <ul>
                <li>
                  <strong>Multi-family homes:</strong> Reducing noise between
                  units.
                </li>
                <li>
                  <strong>Commercial spaces:</strong> Ensuring a peaceful
                  working atmosphere.
                </li>
              </ul>
            </div>

            <div className="product" id="fire-resistant-insulation">
              <h5>Fire-Resistant Insulation</h5>
              <p>
                Safety is paramount. Our fire-resistant insulation products are
                designed to:
              </p>
              <ul>
                <li>Withstand high temperatures.</li>
                <li>Help prevent the spread of fire.</li>
              </ul>
            </div>

            <div className="product" id="eco-friendly-options">
              <h5>Eco-Friendly Options</h5>
              <p>Sustainability is at the core of our innovation:</p>
              <ul>
                <li>Made from recycled materials.</li>
                <li>Contributes to LEED certification.</li>
                <li>
                  Designed to lower energy consumption and reduce environmental
                  impact.
                </li>
              </ul>
            </div>

            <div className="product" id="installation-technology">
              <h5>Installation Technology</h5>
              <p>
                Our products are engineered for easy installation, ensuring a
                seamless experience for:
              </p>
              <ul>
                <li>Contractors.</li>
                <li>Homeowners.</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default About;
