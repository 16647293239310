import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../HomeSliderStyle.scss";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapComponent = () => {
  const position = [23.27455846130896, 77.43984659533096];
  const [hover, setHover] = useState(true);

  return (
    <div className="pt-5 pb-5">
      <MapContainer
        center={position}
        zoom={12}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={position}
          eventHandlers={{
            mouseover: () => {
              setHover(true);
            },
            mouseout: () => {
              setHover(false);
            },
          }}
        >
          <Popup className={hover ? "popup-hover" : ""}>
            <strong>New Tech Engineering</strong>
            <br />
            24, 143-A, Sector H, Govindpura Industrial Area,
            <br /> Bhopal, Madhya Pradesh 462023, India.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
