import React from "react";
import "./Terms.css";
import { contact } from "../../Common/Data";

export default function TermsCondition() {
  return (
    <div className="terms-conditions mt-5 mb-5">
      <h2>Terms and Conditions</h2>
      <p>
        <strong>Effective Date: [Insert Date]</strong>
      </p>
      <p>
        Welcome to New Tech Engineering (Insulation). By accessing or using our
        website, you agree to comply with and be bound by the following terms
        and conditions. If you do not agree to these terms, please do not use
        our site.
      </p>

      <h4>1. Introduction</h4>
      <p>
        These Terms and Conditions govern your use of our website and services.
        They apply to all visitors, users, and others who access our site.
      </p>

      <h4>2. Use of Our Services</h4>
      <p>
        By using our services, you confirm that you are at least 18 years of age
        or accessing the site under the supervision of a parent or guardian. You
        agree to use our services only for lawful purposes and in accordance
        with these Terms.
      </p>

      <h4>3. Intellectual Property Rights</h4>
      <p>
        All content on this site, including text, graphics, logos, and images,
        is the property of New Tech Engineering and is protected by copyright,
        trademark, and other intellectual property laws. You may not reproduce,
        distribute, or create derivative works without our express written
        permission.
      </p>

      <h4>4. User Responsibilities</h4>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password. You agree to notify us immediately of any unauthorized use
        of your account or any other breach of security.
      </p>

      <h4>5. Limitation of Liability</h4>
      <p>
        New Tech Engineering will not be liable for any direct, indirect,
        incidental, special, consequential, or punitive damages resulting from
        your access to or use of our website. We do not guarantee that our site
        will be error-free, uninterrupted, or free of viruses.
      </p>

      <h4>6. Links to Third-Party Websites</h4>
      <p>
        Our website may contain links to third-party sites that are not owned or
        controlled by New Tech Engineering. We have no control over, and assume
        no responsibility for, the content, privacy policies, or practices of
        any third-party sites.
      </p>

      <h4>7. Indemnification</h4>
      <p>
        You agree to defend, indemnify, and hold harmless New Tech Engineering
        and its affiliates from any claims, damages, liabilities, losses, or
        expenses arising from your use of our services or violation of these
        Terms.
      </p>

      <h4>8. Changes to These Terms</h4>
      <p>
        We reserve the right to update or change these Terms at any time. Your
        continued use of the site after any modifications constitutes acceptance
        of the new Terms.
      </p>

      <h4>9. Governing Law</h4>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of Bhopal Madhya Pradesh. Any disputes arising out of or related to
        these Terms will be resolved in the courts of [Your Jurisdiction].
      </p>

      <h4>10. Contact Us</h4>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>
        <strong>New Tech Engineering (Insulation)</strong>
        <br />
        Email: <a href={`mailto:${contact.email}`}>{contact.email}</a>
        <br />
        Phone: {contact.mobile}
        <br />
        Address: {contact.address}
      </p>
    </div>
  );
}
