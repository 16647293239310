import React, { useState, useEffect } from "react";
import "./homepageStyle.css";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { metaDetails, service } from "../Common/Data";
import AppSlider from "./HomeSlider";
import HomePage from "./HomePage/HomePage";
import OurProductsSection from "./Products/OurProductsSection.js";
import OurVision from "./OurVision/OurVision.js";
import MapComponent from "./MapComponent/MapComponent.js";
import ImageGallery from "./ImageGallery/ImageGallery.js";

const Home = () => {
  return (
    <>
      {/* Carousal Section*/}
      <div className="carousal-home">
        <HomePage />
      </div>
      {/* Detailed Section*/}
      <div className="row details-application mt-3">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="area">
            <div className="text-dark">{metaDetails.ISO} Certified Company</div>
            <div className="text-light">
              We take pride in our commitment to quality management and
              continuous improvement.
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="area">
            <div className="text-dark">6 Unique Products</div>
            <div className="text-light">
              We offer 6 unique products tailored to meet the diverse needs of
              our clients.
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="area">
            <div className="text-dark">Global Destinations</div>
            <div className="text-light">
              We operate in global destinations <br /> including India, the USA,{" "}
              <br />
              and other Asian countries.
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="area">
            <div className="text-dark">TurnKey Solutions</div>
            <div className="text-light">
              Our turnkey solutions provide a seamless experience from concept
              to completion.
            </div>
          </div>
        </div>
      </div>
      {/* About Section*/}
      <div className="about-section">
        <div className="mt-5">
          <div style={{ textAlign: "center" }}>
            <Link to="/about" className="about-link">
              <div className="home-services-heading">
                About <span>New Tech Engineering</span>
              </div>
            </Link>
          </div>
          <div className="business-info">
            <h2>Business Information</h2>
            <div className="info-grid row">
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-briefcase"></i>
                </div>
                <div className="text-content">
                  <strong>Nature of Business:</strong>
                  <p>{metaDetails.natureOfBusiness}</p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-users"></i>
                </div>
                <div className="text-content">
                  <strong>Total Number of Employees:</strong>
                  <p>{metaDetails.noOfEmployee}</p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-calendar-alt"></i>
                </div>
                <div className="text-content">
                  <strong>Year of Establishment:</strong>
                  <p>
                    {metaDetails.yearOfStablish}{" "}
                    <span className="highlighted-age">
                      (12 Year Old Company)
                    </span>
                  </p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fas fa-rupee-sign"></i>
                </div>
                <div className="text-content">
                  <strong>Annual Turnover:</strong>
                  <p>{metaDetails.annualTurnOver}</p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-code"></i>
                </div>
                <div className="text-content">
                  <strong>Import Export Code (IEC):</strong>
                  <p>{metaDetails.importExportCode}</p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-gavel"></i>
                </div>
                <div className="text-content">
                  <strong>Legal Status of Firm:</strong>
                  <p>{metaDetails.legalStatusOfFirm}</p>
                </div>
              </div>
              <div className="info-item col-sm-12 col-md-4 col-lg-4">
                <div className="icon-circle">
                  <i className="fa fa-id-card"></i>
                </div>
                <div className="text-content">
                  <strong>GST Number:</strong>
                  <p>{metaDetails.GSTIN}</p>
                </div>
              </div>
            </div>
            <div className="row engineering-description">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h2>New Tech Engineering</h2>
                <p className="center-para">
                  New Tech Engineering (Insulation) specializes in cutting-edge
                  research and development in insulation technologies. With a
                  commitment to innovation, we have established ourselves as
                  leaders in the application of industrial energy-saving heating
                  solutions, both in India and internationally. Our advanced
                  insulation products not only enhance energy efficiency but
                  also contribute to sustainability by reducing heat loss and
                  carbon footprint etc. <br /> <br />
                  Our team of experts is dedicated to continuous improvement,
                  leveraging the latest technologies and materials to provide
                  tailored solutions for various industries. We pride ourselves
                  on our rigorous testing and quality assurance processes,
                  ensuring that our products meet the highest standards. At New
                  Tech Engineering, we are not just meeting industry needs; we
                  are setting new benchmarks for excellence in insulation
                  technology.
                </p>
              </div>
            </div>
            <div className="mt-5 text-center">
              <Link to="/about" className="btn-btn-primary">
                Know More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Product Section*/}
      <div className="product-section">
        <OurProductsSection />
        <div className=" text-center">
          <Link to="/product" className="btn-btn-primary">
            Show All Categories
          </Link>
        </div>
      </div>

      {/* Our Vision Section*/}
      <div className="mt-5">
        <OurVision />
      </div>

      {/* Gallery Section*/}
      <div className="mt-1 mb-1">
        <ImageGallery />
      </div>

      {/* Map Section*/}
      <div className="mb-5">
        <MapComponent />
      </div>
    </>
  );
};

export default Home;
