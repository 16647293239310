import Heater_insulation_cover_1 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_1.jpg";
import Heater_insulation_cover_2 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_2.jpg";
import Heater_insulation_cover_3 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_3.jpg";
import Heater_insulation_cover_4 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_4.jpg";
import Heater_insulation_cover_5 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_5.jpg";
import Heater_insulation_cover_6 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_6.jpg";
import Heater_insulation_cover_7 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_7.jpg";
import Heater_insulation_cover_8 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_8.jpg";
import Heater_insulation_cover_9 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_9.jpg";
import Heater_insulation_cover_10 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_10.jpg";
import Heater_insulation_cover_11 from "./../assets/HeaterInsulationCover/Heater_insulation_cover_11.jpg";

import DGExhaustSilencerPipe_1 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_1.jpg";
import DGExhaustSilencerPipe_2 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_2.jpg";
import DGExhaustSilencerPipe_3 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_3.jpg";
import DGExhaustSilencerPipe_4 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_4.jpg";
import DGExhaustSilencerPipe_5 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_5.jpg";
import DGExhaustSilencerPipe_6 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_6.jpg";
import DGExhaustSilencerPipe_7 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_7.jpg";
import DGExhaustSilencerPipe_8 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_8.jpg";
import DGExhaustSilencerPipe_9 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_9.jpg";
import DGExhaustSilencerPipe_10 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_10.jpg";
import DGExhaustSilencerPipe_11 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_11.jpg";
import DGExhaustSilencerPipe_12 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_12.jpg";
import DGExhaustSilencerPipe_13 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_13.jpg";
import DGExhaustSilencerPipe_14 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_14.jpg";
import DGExhaustSilencerPipe_15 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_15.jpg";
import DGExhaustSilencerPipe_16 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_16.jpg";
import DGExhaustSilencerPipe_17 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_17.jpg";
import DGExhaustSilencerPipe_18 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_18.jpg";
import DGExhaustSilencerPipe_19 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_19.jpg";
import DGExhaustSilencerPipe_20 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_20.jpg";
import DGExhaustSilencerPipe_21 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_21.jpg";
import DGExhaustSilencerPipe_22 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_22.jpg";
import DGExhaustSilencerPipe_23 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_23.jpg";
import DGExhaustSilencerPipe_24 from "./../assets/DGExhaustSilencerPipe/DG_Exhaust_Silencer_Pipe_24.jpg";

import Expansion_bellow_Fabrics_1 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_1.jpg";
import Expansion_bellow_Fabrics_2 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_2.jpg";
import Expansion_bellow_Fabrics_3 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_3.jpg";
import Expansion_bellow_Fabrics_4 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_4.jpg";
import Expansion_bellow_Fabrics_5 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_5.jpg";
import Expansion_bellow_Fabrics_6 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_6.jpg";
import Expansion_bellow_Fabrics_7 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_7.jpg";
import Expansion_bellow_Fabrics_8 from "./../assets/Expansion_bellow_Fabrics/Expansion_bellow_Fabrics_8.jpg";

import Insulation_pipe_line_1 from "./../assets/Insulation_pipe_line/Insulation_pipe_line_1.jpg";
import Insulation_pipe_line_2 from "./../assets/Insulation_pipe_line/Insulation_pipe_line_2.jpg";
import Insulation_pipe_line_3 from "./../assets/Insulation_pipe_line/Insulation_pipe_line_3.jpg";
import Insulation_pipe_line_4 from "./../assets/Insulation_pipe_line/Insulation_pipe_line_4.jpg";

import TurbineInsulationPad_1 from "./../assets/TurbineInsulationPad/Turbine_Insulation_Pad_1.jpg";
import TurbineInsulationPad_2 from "./../assets/TurbineInsulationPad/Turbine_Insulation_Pad_2.jpg";
import TurbineInsulationPad_3 from "./../assets/TurbineInsulationPad/Turbine_Insulation_Pad_3.jpg";
import TurbineInsulationPad_4 from "./../assets/TurbineInsulationPad/Turbine_Insulation_Pad_4.jpg";

import Valve_Insulation_Jacket_1 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_1.jpg";
import Valve_Insulation_Jacket_2 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_2.jpg";
import Valve_Insulation_Jacket_3 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_3.jpg";
import Valve_Insulation_Jacket_4 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_4.jpg";
import Valve_Insulation_Jacket_5 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_5.jpg";
import Valve_Insulation_Jacket_6 from "./../assets/Valve_Insulation_Jacket/Valve_Insulation_Jacket_6.jpg";

//Service JSON data
export const service = [
  {
    id: 27,
    name: "Heater Insulation Cover",
    description: `Our Heater Insulation Cover is designed to enhance the efficiency of your heating system while reducing energy costs. Made from high-quality, thermal-resistant materials, this cover effectively traps heat, preventing it from escaping and ensuring that your space stays warm and cozy`,
    img1: Heater_insulation_cover_1,
    img2: Heater_insulation_cover_2,
    img3: Heater_insulation_cover_3,
    img4: Heater_insulation_cover_4,
    img5: Heater_insulation_cover_5,
    img6: Heater_insulation_cover_6,
    img7: Heater_insulation_cover_7,
    img8: Heater_insulation_cover_8,
    img9: Heater_insulation_cover_9,
    img10: Heater_insulation_cover_10,
    img11: Heater_insulation_cover_11,
    sub_category: [],
    whyChoose: {
      label: "Why Choose Our Heater Insulation Cover?",
      des: "Investing in our insulation cover not only improves the comfort of your living or working space but also contributes to a greener environment by minimizing energy consumption. Say goodbye to chilly drafts and hello to a warm, inviting atmosphere!",
    },
    feature: [
      {
        label: "Energy Efficient",
        des: "Reduces heat loss, helping you save on energy bills.",
      },
      {
        label: "Durable Material",
        des: "Constructed from premium insulation fabric that withstands wear and tear.",
      },
      {
        label: "Easy Installation",
        des: "Simple to install with adjustable straps to fit various heater sizes.",
      },
      {
        label: "Versatile Design",
        des: "Suitable for both residential and commercial heating systems.",
      },
      {
        label: "Safety First",
        des: "Fire-retardant materials ensure safe use without compromising heating performance.",
      },
    ],
    applications: [],
    Maximum_Temperature: "1200 degree Celsius",
    Density: "96 to 128 kg/m^3",
    Thickness: "12mm to 50mm",
    material: "High temperature clothes",
    Packaging_type: "",
    color: "As per your request",
    usages: "High temperature casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 28,
    name: "Turbine Insulation Pad",
    description: `Introducing the Turbine Insulation Pad, specifically designed to improve the thermal management of turbines in various industrial applications. Our high-performance insulation pad helps maintain optimal operating temperatures, ensuring efficiency and longevity of your turbine systems.`,
    img1: TurbineInsulationPad_1,
    img2: TurbineInsulationPad_2,
    img3: TurbineInsulationPad_3,
    img4: TurbineInsulationPad_4,
    sub_category: [],
    feature: [
      {
        label: "Superior Thermal Insulation",
        des: " Made from advanced materials that provide exceptional heat resistance, minimizing energy loss.",
      },
      {
        label: "Durable Construction",
        des: " Engineered to withstand harsh environments, ensuring long-lasting performance and reliability.",
      },
      {
        label: "Lightweight Design",
        des: "Easy to handle and install, reducing labor costs and installation time.",
      },
      {
        label: "Custom Fit Options",
        des: "Available in various sizes and shapes to accommodate different turbine models and specifications.",
      },
      {
        label: "Enhanced Safety",
        des: "Reduces the risk of burns and injuries by providing a protective barrier against high temperatures.",
      },
    ],
    applications: [],
    Maximum_Temperature: "1260 Degree Celsius",
    Density: "",
    Thickness: "12.5-100 mm",
    material: "	CERAMIC FIBERGLASS CLOTH & CERAMIC BLANKET",
    Packaging_type: "",
    color: "As per your request",
    usages: "Turbine Casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 30,
    name: "Valve Insulation Jacket",
    description: `Introducing the Valve Insulation Jacket, your solution for enhanced thermal efficiency in valve systems. Designed to minimize heat loss and prevent condensation, our insulation jacket is essential for maintaining optimal temperatures in various industrial applications.`,
    img1: Valve_Insulation_Jacket_1,
    img2: Valve_Insulation_Jacket_2,
    img3: Valve_Insulation_Jacket_3,
    img4: Valve_Insulation_Jacket_4,
    img5: Valve_Insulation_Jacket_5,
    img6: Valve_Insulation_Jacket_6,
    sub_category: [],
    feature: [
      {
        label: "Exceptional Thermal Insulation",
        des: "Crafted from high-quality insulation materials that effectively retain heat, reducing energy consumption and costs.",
      },
      {
        label: "Durable and Weather-Resistant",
        des: "Built to withstand extreme conditions, ensuring long-lasting performance in demanding environments.",
      },
      {
        label: "Easy Installation",
        des: " Lightweight and user-friendly design allows for quick installation on any valve type, with adjustable straps for a secure fit.",
      },
      {
        label: "Customizable Sizes",
        des: "Available in a variety of dimensions to suit different valve sizes and configurations.",
      },
      {
        label: "Enhanced Safety",
        des: "Provides a protective barrier against burns and accidents, promoting a safer work environment.",
      },
    ],
    applications: [],
    Maximum_Temperature: "850 Degree Celsius",
    Density: "Silicon Rubber Coated",
    Thickness: "12-50mm",
    material: "	Ceramic Blanket And Silicon Cloth",
    Packaging_type: "",
    color: "Grey",
    usages: "Valve insulation",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 31,
    name: "Insulation Pipe Line",
    description: `Introducing our Insulation Pipe Line, designed to enhance thermal efficiency and reduce energy costs in a variety of applications. Whether for residential, commercial, or industrial use, our insulation solutions provide superior protection against heat loss and condensation, ensuring optimal performance of your piping systems.`,
    img1: Insulation_pipe_line_1,
    img2: Insulation_pipe_line_2,
    img3: Insulation_pipe_line_3,
    img4: Insulation_pipe_line_4,
    sub_category: [],
    feature: [
      {
        label: "High-Performance Insulation",
        des: "Made from advanced insulation materials that offer excellent thermal resistance, keeping your pipes at the desired temperature.",
      },
      {
        label: "Condensation Control",
        des: " Reduces the risk of condensation and mold growth, promoting a healthier environment.",
      },
      {
        label: "Durable Construction",
        des: "Built to withstand harsh conditions, ensuring long-lasting performance and minimal maintenance.",
      },
      {
        label: "Versatile Applications",
        des: "Ideal for HVAC systems, plumbing, refrigeration, and other piping needs.",
      },
      {
        label: "Easy Installation",
        des: " Lightweight and flexible design allows for quick and efficient installation on any pipe configuration.",
      },
    ],
    applications: [],
    Maximum_Temperature: "800 Degree Celsius",
    Density: "Silicon Rubber Coated",
    Thickness: "12-50mm",
    material: "	Insulation Covers",
    Packaging_type: "",
    color: "Grey",
    usages: "Temperature control / energy saving",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 32,
    name: "Expansion Bellow Fabrics",
    description: `Introducing Expansion Bellow Fabrics 6, your go-to solution for effective movement and vibration control in various applications. These high-performance fabrics are designed to provide exceptional flexibility and durability, making them ideal for a wide range of industrial and commercial uses.`,
    img1: Expansion_bellow_Fabrics_1,
    img2: Expansion_bellow_Fabrics_2,
    img3: Expansion_bellow_Fabrics_3,
    img4: Expansion_bellow_Fabrics_4,
    img5: Expansion_bellow_Fabrics_5,
    img6: Expansion_bellow_Fabrics_6,
    img7: Expansion_bellow_Fabrics_7,
    img8: Expansion_bellow_Fabrics_8,
    sub_category: [],
    feature: [
      {
        label: "High Strength and Durability",
        des: "Constructed from robust materials that withstand high temperatures and harsh conditions.",
      },
      {
        label: "Excellent Flexibility",
        des: " Designed to handle significant movement and expansion, reducing wear and tear on equipment.",
      },
      {
        label: "Chemical Resistant",
        des: "Ideal for use in environments where exposure to chemicals is a concern, ensuring longevity and reliability.",
      },
      {
        label: "Customizable Sizes",
        des: "Available in various dimensions to suit specific project requirements.",
      },
      {
        label: "Easy Installation",
        des: "Lightweight and user-friendly, allowing for quick and hassle-free installation.",
      },
    ],
    applications: [],
    Maximum_Temperature: "800 degree Celsius",
    Density: "Silicon Rubber Coated",
    Thickness: "12mm to 50mm",
    material: "	Cerawool, mineral wool, high temperature cloth etc",
    Packaging_type: "",
    color: "As per your request",
    usages: "Injection molding Machine, All types extrusion machine",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 34,
    name: "DG Exhaust Silencer Pipe",
    description: `The DG Exhaust Silencer Pipe is engineered for superior performance and sound management in your vehicle. Designed specifically for [insert vehicle type or model], this silencer pipe enhances exhaust flow while significantly reducing noise levels, allowing you to enjoy a smoother and quieter ride.`,
    img1: DGExhaustSilencerPipe_1,
    img2: DGExhaustSilencerPipe_2,
    img3: DGExhaustSilencerPipe_3,
    img4: DGExhaustSilencerPipe_4,
    img5: DGExhaustSilencerPipe_5,
    img6: DGExhaustSilencerPipe_6,
    img7: DGExhaustSilencerPipe_7,
    img8: DGExhaustSilencerPipe_8,
    img9: DGExhaustSilencerPipe_9,
    img10: DGExhaustSilencerPipe_10,
    img11: DGExhaustSilencerPipe_11,
    img12: DGExhaustSilencerPipe_12,
    img13: DGExhaustSilencerPipe_13,
    img14: DGExhaustSilencerPipe_14,
    img15: DGExhaustSilencerPipe_15,
    img16: DGExhaustSilencerPipe_16,
    img17: DGExhaustSilencerPipe_17,
    img18: DGExhaustSilencerPipe_18,
    img19: DGExhaustSilencerPipe_19,
    img20: DGExhaustSilencerPipe_20,
    img22: DGExhaustSilencerPipe_22,
    img23: DGExhaustSilencerPipe_23,
    img24: DGExhaustSilencerPipe_24,
    sub_category: [],
    feature: [
      {
        label: "High-Quality Construction",
        des: "Crafted from durable, corrosion-resistant materials for long-lasting performance.",
      },
      {
        label: "Enhanced Sound Control",
        des: "Effectively reduces exhaust noise, providing a more pleasant driving experience.",
      },
      {
        label: "Improved Exhaust Flow",
        des: "Optimized design promotes better airflow, improving engine performance and efficiency.",
      },
      {
        label: "Easy Installation",
        des: "Direct-fit design makes installation a breeze, with all necessary hardware included.",
      },
      {
        label: "Versatile Compatibility",
        des: "Suitable for a wide range of vehicles, including [list compatible models or types]",
      },
    ],
    whyChoose:
      "Upgrade your vehicle with the DG Exhaust Silencer Pipe to achieve a perfect balance of performance and quiet operation. Whether you’re on the highway or tackling tough terrain, this silencer pipe ensures you get the most out of your engine without the unwanted noise.",
    applications: [],
    Maximum_Temperature: "1200 degree Celsius",
    Density: "Unidirectional",
    Thickness: ">20 mm",
    material: "	High temp. fabric,Wool With ss wire mesh",
    Packaging_type: "",
    color: "As per your request",
    usages: "	For heat casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
];

//Contact Details
export const contact = {
  email: "info@newtechinsulation.in",
  address: ` 24, 143-A, Sector H, Govindpura,
          Industrial Area, Bhopal, Madhya Pradesh 462023, India.`,
  mobile: `+91-6264021848
           +91-7879487462`,
  language: "Hindi, English",
};

//metaDetails:
export const metaDetails ={
  ISO:"ISO 9001:2015",
  PAN:"FFDPS7194E",
  GSTIN:"23FFDPS7194E1Z6",
  yearOfStablish:"2022",
  yearOfExp:"12+ Years",
  noOfEmployee:"45+",
  annualTurnOver:"INR 4.5 Crore",
  importExportCode:"FFDPS7194E",
  legalStatusOfFirm:"Proprietorship",
  natureOfBusiness: "Manufacturing"
}

//Title
export const title = "NEW TECH ENGINEERING";

export const imageGallery = [
  {
    name: "Heater Insulation Cover",
    id: 27,
    img: [
      Heater_insulation_cover_1,
      Heater_insulation_cover_2,
      Heater_insulation_cover_3,
      Heater_insulation_cover_4,
      Heater_insulation_cover_5,
      Heater_insulation_cover_6,
      Heater_insulation_cover_7,
      Heater_insulation_cover_8,
      Heater_insulation_cover_9,
      Heater_insulation_cover_10,
      Heater_insulation_cover_11,
    ],
  },
  {
    name: "DG Exhaust Silencer Pipe",
    id: 34,
    img: [
      DGExhaustSilencerPipe_1,
      DGExhaustSilencerPipe_2,
      DGExhaustSilencerPipe_3,
      DGExhaustSilencerPipe_4,
      DGExhaustSilencerPipe_5,
      DGExhaustSilencerPipe_6,
      DGExhaustSilencerPipe_7,
      DGExhaustSilencerPipe_8,
      DGExhaustSilencerPipe_9,
      DGExhaustSilencerPipe_10,
      DGExhaustSilencerPipe_11,
      DGExhaustSilencerPipe_12,
      DGExhaustSilencerPipe_13,
      DGExhaustSilencerPipe_14,
      DGExhaustSilencerPipe_15,
      DGExhaustSilencerPipe_16,
      DGExhaustSilencerPipe_17,
      DGExhaustSilencerPipe_18,
      DGExhaustSilencerPipe_19,
      DGExhaustSilencerPipe_20,
      DGExhaustSilencerPipe_22,
      DGExhaustSilencerPipe_23,
      DGExhaustSilencerPipe_24,
    ],
  },
  {
    name: "Expansion Bellow Fabrics",
    id: 32,
    img: [
      Expansion_bellow_Fabrics_1,
      Expansion_bellow_Fabrics_2,
      Expansion_bellow_Fabrics_3,
      Expansion_bellow_Fabrics_4,
      Expansion_bellow_Fabrics_5,
      Expansion_bellow_Fabrics_6,
      Expansion_bellow_Fabrics_7,
      Expansion_bellow_Fabrics_8,
    ],
  },
  {
    name: "Insulation Pipe Line",
    id: 31,
    img: [
      Insulation_pipe_line_1,
      Insulation_pipe_line_2,
      Insulation_pipe_line_3,
      Insulation_pipe_line_4,
    ],
  },
  {
    name: "Turbine Insulation Pad",
    id: 28,
    img: [
      TurbineInsulationPad_1,
      TurbineInsulationPad_2,
      TurbineInsulationPad_3,
      TurbineInsulationPad_4,
    ],
  },
  {
    name: "Valve Insulation Jacket",
    id: 30,
    img: [
      Valve_Insulation_Jacket_1,
      Valve_Insulation_Jacket_2,
      Valve_Insulation_Jacket_3,
      Valve_Insulation_Jacket_4,
      Valve_Insulation_Jacket_5,
      Valve_Insulation_Jacket_6,
    ],
  },
];
