import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";

export default function ModalComponent(props) {
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.isShow}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{props.modalImage?.name}</h3>
            {/* <h4>{props.modalImage.id}</h4> */}
          </Modal.Title>
          <Button onClick={props.closeModal} className="closeButton">
            X
          </Button>
        </Modal.Header>
        {props?.isSingleImage ? (
          <Modal.Body>
            {props?.modalImage && (
              <div className="slider-container-modal">
                <img
                  src={props.modalImage?.src}
                  alt={props.modalImage?.src}
                  className="image"
                />
              </div>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body>
            {props.modalImage && (
              <div
                className="slider-container-modal"
                onMouseEnter={() => props.setIsHovering(true)}
                onMouseLeave={() => props.setIsHovering(false)}
                onClick={(e) => e.stopPropagation()}
              >
                <Slider {...settings}>
                  {props.modalImage?.img?.map((eachImg, i) => (
                    <div>
                      <img
                        key={i}
                        src={eachImg}
                        alt={props.modalImage?.name}
                        className="image"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button onClick={props.closeModal} className="closeButton">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
