const initailState = {
  productDetail: {},
};

const ProductReducers = (state = initailState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_DETAILS":
      return {
        ...state,
        productDetail: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export default ProductReducers;
