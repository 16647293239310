import React, { useEffect, useState } from "react";
import ModalComponent from "../Components/ModalCom.js";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { imageGallery, service } from "../Common/Data.js";
import Slider from "react-slick";

const ServiceDetails = (props) => {
  const { id } = useParams();
  const [isShow, setIsShow] = useState(false);
  const [clickedImg, setClickedImg] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const productDetails = useSelector(
    (state) => state.ProductReducers && state.ProductReducers.productDetail
  );

  useEffect(() => {
    if (id && service && imageGallery) {
      const newData = service?.filter((item) => item.id == id);
      const matchedImages = imageGallery?.find((image) => image.id == id);
      if (newData.length > 0 && matchedImages) {
        const updatedData = {
          ...newData[0],
          img: matchedImages.img,
        };

        setCategoryData([updatedData]);
      }
    }
  }, [id, service, imageGallery]);

  const handleOpenImage = (e, img) => {
    e.preventDefault();
    setIsShow(true);
    setClickedImg(img);
  };

  const closeModal = () => {
    setIsShow(false);
  };

  const handleBack = () => {
    navigate("/product");
  };
  const handleHomeButton = () => {
    navigate("/");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <>
      <div className="blogs-content">
        {categoryData &&
          categoryData?.map((data, i) => {
            return (
              <div key={data.id}>
                <div className="products">
                  <div className="product_details">
                    <h3>{data?.name}</h3>
                  </div>

                  <div className="row gy-3 product_img product_background">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="slider-container">
                        <Slider {...settings}>
                          {data?.img?.map((eachImg, i) => (
                            <div onClick={() => handleOpenImage(data?.img)}>
                              <img
                                key={i}
                                src={eachImg}
                                alt={data?.name}
                                className="image"
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 description-area">
                      <div className="main-content">
                        <div className="">
                          <u>Description :</u>
                          <p>{data?.description}</p>
                        </div>
                        {data?.Maximum_Temperature !== "" && (
                          <div className="det_area">
                            <u>Maximum Temperature :</u>
                            &nbsp;&nbsp;
                            <p>{data?.Maximum_Temperature}</p>
                          </div>
                        )}

                        {data?.Density !== "" && (
                          <div className="det_area">
                            <u>Density :</u>
                            &nbsp;&nbsp;
                            <p>{data?.Density}</p>
                          </div>
                        )}
                        {data?.Thickness !== "" && (
                          <div className="det_area">
                            <u>Thickness :</u>
                            &nbsp; &nbsp;
                            <p>{data?.Thickness}</p>
                          </div>
                        )}
                        {data?.Packaging_type !== "" && (
                          <div className="det_area">
                            <u>Packaging Type :</u>
                            &nbsp;&nbsp;
                            <p>{data?.Packaging_type}</p>
                          </div>
                        )}
                        {data?.usages !== "" && (
                          <div className="det_area">
                            <u>Usage/Application:</u>
                            &nbsp;&nbsp;
                            <p>{data?.usages}</p>
                          </div>
                        )}
                        {data?.color !== "" && (
                          <div className="det_area">
                            <u>Color:</u>
                            &nbsp;&nbsp;
                            <p>{data?.color}</p>
                          </div>
                        )}
                        {data?.material !== "" && (
                          <div className="det_area">
                            <u>Material:</u>
                            &nbsp;&nbsp;
                            <p>{data?.material}</p>
                          </div>
                        )}
                        {data?.country_of_origin !== "" && (
                          <div className="det_area">
                            <u>Country of Origin:</u>
                            &nbsp;&nbsp;
                            <p>{data?.country_of_origin}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {data?.feature.length > 0 && (
                  <div className="feature_area">
                    <h4>
                      <u>Features :</u>
                    </h4>
                    <ol>
                      {data?.feature &&
                        data?.feature?.map((_feature, index) => {
                          return (
                            <li key={index}>
                              <strong>{_feature.label}</strong> : {_feature.des}
                            </li>
                          );
                        })}
                    </ol>
                  </div>
                )}
                {data?.applications.length > 0 && (
                  <div className="applications_area">
                    <h4>
                      <u>Applications :</u>
                    </h4>
                    <ol>
                      {data?.applications &&
                        data?.applications?.map((application, index) => {
                          return (
                            <li key={application.id}>{application.value}</li>
                          );
                        })}
                    </ol>
                  </div>
                )}

                <div className="note_area">
                  <h4>Note :</h4>
                  <p>{data?.Note}</p>
                </div>
              </div>
            );
          })}

        <div className="bottom_area"></div>
      </div>
    </>
  );
};

export default ServiceDetails;
