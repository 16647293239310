import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { contact } from "./../../Common/Data";
import Logo from "../../assets/newtechengineering-bg.png";
import Container from "react-bootstrap/Container";
import Header from "./Header";
import "./Header.css";
import { service } from "./../../Common/Data.js";

const Menubar = () => {
  const location = useLocation();

  const handleCollapse = () => {
    const nav = document.getElementById("navbarNav");
    const btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  const handleClickTheme = () => {
    document.body.classList.toggle("light-mode");
    handleCollapse();
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar-bg">
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/">
            <img src={Logo} alt="new tech" className="logo-image" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mb-2 mb-lg-0">
            <Nav.Link
              as={Link}
              to="/"
              className={location.pathname === "/" ? "active active-style" : ""}
              onClick={handleCollapse}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              className={
                location.pathname === "/about" ? "active active-style" : ""
              }
              onClick={handleCollapse}
            >
              About
            </Nav.Link>
            <NavDropdown
              title="Products"
              id="navbarDropdown"
              className={
                location.pathname.startsWith("/product")
                  ? "active active-style"
                  : ""
              }
              onClick={handleCollapse}
            >
              {service &&
                service?.map((item) => {
                  return (
                    <NavDropdown.Item
                      as={Link}
                      key={item.id}
                      to={`/product/category/${item.id}`}
                    >
                      {item.name}
                    </NavDropdown.Item>
                  );
                })}
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/gallery"
              className={
                location.pathname === "/gallery" ? "active active-style" : ""
              }
              onClick={handleCollapse}
            >
              Gallery
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={
                location.pathname === "/contact" ? "active active-style" : ""
              }
              onClick={handleCollapse}
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menubar;
