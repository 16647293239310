import React from "react";
import { contact, title } from "./../Common/Data";

const Contact = () => {
  return (
    <section className="contact">
      <div className="ps-3">
        <div className="main-title mt-5">
          <h2>
            Contact <span>Us</span>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 title">
            <h4>{title}</h4>
            <br />
          </div>
        </div>
        <div className="row contact-detail">
          <div className="col-sm-4 col-md-3 col-lg-2">
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;
              <span>
                <b>Location:</b>
              </span>
            </div>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-10">
            <span>{contact?.address}</span>
          </div>
        </div>
        <div className="row contact-detail">
          <div className="col-sm-4 col-md-3 col-lg-2">
            <div className="icon">
              <i className="fas fa-envelope"></i> &nbsp;&nbsp;
              <span>
                <b>Email:</b>
              </span>
            </div>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-10">
            <a href={`mailto:${contact?.email}`} className="contact-link">
              {contact?.email}
            </a>
          </div>
        </div>
        <div className="row contact-detail">
          <div className="col-sm-4 col-md-3 col-lg-2">
            <div className="icon">
              <i className="fa fa-phone"></i> &nbsp;&nbsp;
              <span>
                <b>Phone:</b>
              </span>
            </div>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-10">
            <span>{contact?.mobile}</span>
          </div>
        </div>
        <div className="row contact-detail">
          <div className="col-sm-4 col-md-3 col-lg-2">
            <div className="icon">
              <i className="fas fa-globe-africa"></i> &nbsp;&nbsp;
              <span>
                <b>Languages:</b>
              </span>
            </div>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-10">
            <span>{contact?.language}</span>
          </div>
        </div>
        <div className="contact-detail">
          <div className="icon">
            <span>
              For more info &nbsp; <i className="fa fa-paper-plane"></i> &nbsp;
              <a
                className="link-mail"
                href={`mailto:${contact?.email}?subject=NTI Product Inquiry`}
              >
                <u>Send query</u>
              </a>
            </span>
          </div>
        </div>

        <div className="contact-icons">
          <div className="contact-icon">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/i/flow/login"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/accounts/login/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
