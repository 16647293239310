// src/GalleryPage.js
import React, { useEffect, useState } from "react";
import { imageGallery } from "../../Common/Data.js";
import "./gallery.css";
import ModalComponent from "../../Components/ModalCom.js";

const GalleryPage = () => {
  const [modalImage, setModalImage] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isGalleryPage, setIsGalleryPage] = useState(false);

  const openModal = (image, imgName) => {
    setModalImage({ name: imgName, src: image });
    setIsOpenModal(true);
    setIsGalleryPage(true);
  };

  const closeModal = (e) => {
    if (e.type === "keydown" && e.key === "Escape") {
      setModalImage(null);
      setIsOpenModal(false);
      setIsGalleryPage(false);
    }
    if (e.type === "click" && !isHovering) {
      setModalImage(null);
      setIsOpenModal(false);
      setIsGalleryPage(false);
    }
  };

  // Adding event listener to handle 'Esc' key
  useEffect(() => {
    if (modalImage) {
      window.addEventListener("keydown", closeModal);
    }
    return () => {
      window.removeEventListener("keydown", closeModal);
    };
  }, [modalImage]);

  return (
    <div className="gallery-page mt-5">
      {imageGallery &&
        imageGallery.map((image, index) => (
          <div key={index} className="row">
            <h5 className="mt-5">{image.name}</h5>
            {image.img.map((_img, i) => (
              <div
                className="col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-3"
                key={i}
              >
                <img
                  src={_img}
                  alt={`Gallery image ${i + 1}`}
                  className="gallery-image"
                  onClick={() => openModal(_img, image.name)}
                />
              </div>
            ))}
          </div>
        ))}
      {isOpenModal && (
        <ModalComponent
          isShow={isOpenModal}
          modalImage={modalImage}
          closeModal={closeModal}
          setIsHovering={setIsHovering}
          isSingleImage={true}
        />
      )}
    </div>
  );
};

export default GalleryPage;
