import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./gallery.css";
import { imageGallery } from "../../Common/Data.js";
import ModalComponent from "../../Components/ModalCom.js";

const ImageGallery = () => {
  const [modalImage, setModalImage] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  // Function to open modal and show image
  const openModal = (image) => {
    setModalImage(image);
    setIsOpenModal(true);
  };

  // Function to close modal when Esc key is pressed or clicking outside the modal
  const closeModal = (e) => {
    if (e.type === "keydown" && e.key === "Escape") {
      setModalImage(null);
      setIsOpenModal(false);
    }
    if (e.type === "click" && !isHovering) {
      setModalImage(null);
      setIsOpenModal(false);
    }
  };

  // Adding event listener to handle 'Esc' key
  useEffect(() => {
    if (modalImage) {
      window.addEventListener("keydown", closeModal);
    }
    return () => {
      window.removeEventListener("keydown", closeModal);
    };
  }, [modalImage]);

  const handleShowMore = () => {
    navigate("/gallery");
  };

  return (
    <>
      <div className="text-center mt-5 mb-5 my-gallery">
        <h1>
          Our <span>Gallery</span>
        </h1>
      </div>
      <div className="gallery mt-3 row">
        {imageGallery &&
          imageGallery.slice(0, 6).map((image, index) => (
            <div key={index} className="col-sm-12 col-lg-4 col-md-4 col-xl-4">
              <h6>{image.name}</h6>
              <img
                src={image.img[0]}
                alt={`Gallery image ${index + 1}`}
                onClick={() => openModal(image)}
                className="gallery-image"
              />
            </div>
          ))}

        {isOpenModal && (
          <ModalComponent
            isShow={isOpenModal}
            modalImage={modalImage}
            closeModal={closeModal}
            setIsHovering={setIsHovering}
          />
        )}
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="btn-section">
            <button onClick={handleShowMore} className="show-more">
              Show More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageGallery;
